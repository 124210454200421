<template>
<div class="examin" v-loading="pageLoading">
      
      <img src="@/assets/images/videoResult.png" />
      <div class="examinText">交卷成功</div>
      <p class="text1">交卷成功2个工作日后可查看考试成绩</p>
      
</div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      id:'',
      pageLoading:false
    };
  },
  async mounted() {
    this.id = this.$route.query.id
    // this.isPass = this.$route.query.isPass==1 ? true : false
   
  },
  destroyed() {
   
  },
  computed: {
   
  },
  methods: {
    toExaminList(){
     
      this.$router.push({path:"./certSave?examinId="+this.$route.query.examinId})
    },
    toDetail(){
      this.$router.push({path:"./examinResultDetail?id="+this.id+"&examinId="+this.$route.query.examinId})
    },
    toHome(){
      this.$router.push({path:"./index"})
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.examin {
  min-height: calc(100vh - 330px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333333;
  width:1200px;
  background: #f6f9fc;
  border-radius: 8px;
  margin: 20px auto;
  text-align: center;
  
  
  .examinText{
    font-size: 22px;
    color: #333333;
  }
  .text1{
    color: #666;
    font-size: 16px;
    margin: 10px auto 20px;
  }

  
}
</style>

<style lang="scss">
</style>
